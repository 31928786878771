import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function Offermenu() {

    const [tabMenu, tabActive] = useState({ a: true });

    return (
        <>
            <section className="row_am offer_menu_section">
                <div className="container">
                    <div className="section_title text-center" data-aos="fade-up" data-aos-duration="1500">
                        <span className="sub_text">Lezzetlerimizi Deneyin</span>
                        <h2>Restoran Menü</h2>
                    </div>
                    <br /><br />
                    {/* <ul className="nav nav-tabs" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration="1500">
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.a && "active"}`} onClick={() => tabActive({ a: true })}
                            id="lunch-tab" data-toggle="tab" to="#lunch" role="tab" aria-controls="lunch" aria-selected="true">Lunchaaa</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.b && "active"}`} onClick={() => tabActive({ b: true })}
                             id="dinner-tab" data-toggle="tab" to="#profile" role="tab" aria-controls="profile" aria-selected="false">Dinner</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.c && "active"}`} onClick={() => tabActive({ c: true })}
                            id="breakfast-tab" data-toggle="tab" to="#breakfast" role="tab" aria-controls="breakfast" aria-selected="false">Breakfast</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.d && "active"}`} onClick={() => tabActive({ d: true })}
                            id="winelist-tab" data-toggle="tab" to="#winelist" role="tab" aria-controls="winelist" aria-selected="false">Winelist</Link>
                        </li>
                    </ul> */}
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration="1500">
                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="lunch" role="tabpanel" aria-labelledby="lunch-tab">
                            <div className="menulist_wraper">
                                <div className="menulist left_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Alkolsüz Kokteyler</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Redline</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Green Bob</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Yalnız Kovboy</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>William</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Red Kit</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Şerif</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Moris</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>lucky Luke</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Joe</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Jack</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li><li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Avarel</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Soğuk İçecekler</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Milkshake</h4>

                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Çikolatalı, Çilekli, Muzlu, Kahveli, Kivil, Vanilyalı, Karamel, Oreo)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Limonata</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Ananas, Yeşil Elma, Çilek, Muz, Kavun, Mango, Kм)
</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Frappe</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Vanilyalı, Çikolatalı, Cookies)
</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Smoothie</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Çilekli, Vişne, Mango, Kivi, Frambuaz)
</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Ice Chocolate</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Beyaz Çikolata, Çilekli, Hindistan Cevizli, Muzlu, Oreo)

</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Mojito</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Çilekli, Karpuzlu, Mango, Elma)

</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="menulist right_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Döner Menü</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Hatay Usulu Tavuk Dürüm  </h4>
                                                        <span className="new">Lezzet</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>Özel baharatlarla marine edilmiş tavuk, taptaze sebzeler, tahin sosu ve lavaş ekmeği ile hazırlanan enfes Hatay usulü dürüm</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>İskenderun Usulu Tavuk Dürüm  </h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>Özel baharatlarla marine edilmiş tavuk, taptaze sebzeler ve nar ekşili sos ile lavaş ekmeğinde sunulan enfes İskenderun usulü dürüm.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tavuk Dürüm Kaşarlı</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>Lezzetli tavuk parçaları, erimiş kaşar peyniri ve taptaze sebzeler ile hazırlanan dürüm, lavaş ekmeğinde sunulur. </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Pilav Üstü Tavuk Döner</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>İnce dilimlenmiş tavuk döner, tereyağlı pilav üzerinde sunuluyor. Yanında taze salata ve turşu ile tamamlanan bu klasik lezzet
                                                    </p>
                                                </div>
                                            </li>
                                             <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Zurna Dürüm</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>"Bol malzemeli, dev boyutlu zurna dürüm; lezzetli tavuk döner, taze sebzeler ve özel soslarla lavaş ekmeğinde sunulur
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Çift Lavaş Boru Dürüm</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>İki katmanlı lavaş ekmeği içinde, zengin içerikli tavuk döner, sebzeler ve soslarla dolu enfes bir dürüm.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Sıcak İçecekler</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Salep</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Daml Sakızlı, Fındıklı Çilekli)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Sıcak Çikolata</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Beyaz Çikolata, Fındıklı, Çilekli, Muzlu)</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Oralet</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>(Elma, Kiwi, Portakal, Tarçın)</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="profile" role="tabpanel" aria-labelledby="dinner-tab">
                            <div className="menulist_wraper">
                                <div className="menulist left_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Starter</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>raw marbled meat steak</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Medium beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">wine list</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kagoshima Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Lobster Tortellini</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tokusen Wagyu</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="menulist right_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Main Dishes</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>raw marbled meat steak</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper
                                                        together lemon juice, soy sauce, sugar, salt, and pepperin a small bowl.
                                                        pour over beef and toss to coat</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Medium beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$18.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes, mushrooms & spinach.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Dessert</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kagoshima Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Banana Nut Pancakes</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tokusen Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="pdf_btn text-center mt-5">
                                <Link to="#" className="btn btn_primary">download menu pdf</Link>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && "show active"}`} id="breakfast" role="tabpanel" aria-labelledby="breakfast-tab">
                            <div className="menulist_wraper">
                                <div className="menulist left_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Starter</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Poached fried Beef Eggs </h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p> Tomatoes with Beef Eggs poached fried chorizo, roasted roma.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Pear Tonic Prickly</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>salt, and pepper in a small bowl Siced beef in a large bowl. Whisk together lemon juice, Soy sauce, sugar,.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Crispy Skin Chicken</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima Medium beef steak grill pan</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">wine list</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kagoshima Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Lobster Tortellini</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tokusen Wagyu</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="menulist right_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Main Dishes</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Medium beef steak grill pan Grill pan </h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>together lemon juice, soy sauce, sugar, together lemon juice, soy sauce, sugar,  </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4> steak rawMeat  marbled </h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Together lemon juice, soy sauce, sugar, salt, and pepperin a small bowl.
                                                        pour over beef and toss to coat Siced beef in a large bowl. Whisk together lemon juice.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Medium beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$18.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes, mushrooms & spinach.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Dessert</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kagoshima Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Banana Nut Pancakes</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tokusen Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="pdf_btn text-center mt-5">
                                <Link to="#" className="btn btn_primary">download menu pdf</Link>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.d && "show active"}`} id="winelist" role="tabpanel" aria-labelledby="winelist-tab">
                            <div className="menulist_wraper">
                                <div className="menulist left_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Starter</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>raw marbled meat steak</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Medium beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">wine list</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kagoshima Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Lobster Tortellini</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tokusen Wagyu</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="menulist right_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Main Dishes</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>raw marbled meat steak</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper
                                                        together lemon juice, soy sauce, sugar, salt, and pepperin a small bowl.
                                                        pour over beef and toss to coat</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Medium beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Beef steak grill pan</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$18.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes, mushrooms & spinach.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Dessert</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kagoshima Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$16.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Beef, Eggs, poached, fried, with bacon, chorizo, roasted roma tomatoes.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Banana Nut Pancakes</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$25.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Siced beef in a large bowl. Whisk together lemon juice, soy sauce, sugar, salt, and pepper in
                                                        a small bowl.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Tokusen Wagyu</h4>
                                                        <span className="new">NEW</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">$22.00</span>
                                                </div>
                                                <div className="description">
                                                    <p>Galjoen fish Australian lungfish sea, Shad angler arapaima</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="pdf_btn text-center mt-5">
                                <Link to="#" className="btn btn_primary">download menu pdf</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Offermenu
import React from 'react'
import Slider from 'react-slick'

const Text = {
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 11,
            autoplaySpeed: 2000,
            easing: "linear"

          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
}
// ISKENDERUN USULÜ TAVUK DURUM
// HATAY USULU TAVUK DURUM
// TAVUK DURUM KAŞARLI
// HATAY USULU TAVUK DURUM CIPS+COLA TATLI
// TAVUK REDD KIT SPECIAL DONER
// PILAV ÜSTÜ TAVUK DÖNER
// TAVUK DÖNER (PORSIYON)
// ZURNA TAVUK DÜRÜM
//
function Textlist() {
    return (
        <>
            <div className="textFlow_list white_cover">
                <Slider className="Slick-Menu" id="text_list_flow" {...Text} arrows={false} rtl={true} slidesToShow={6} autoplay={true} speed={1500} easing='linear' autoplaySpeed={0} style={{ "width": 5697 }}>
                    <div className="item">
                        <div className="text_block">
                            <span>HATAY USULU TAVUK DURUM</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>İSKENDERUN USULÜ TAVUK DURUM</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>TAVUK DURUM KAŞARLI</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span> TAVUK REDD KIT SPECIAL DONER </span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>PILAV ÜSTÜ TAVUK DÖNER</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>ZURNA TAVUK DÜRÜM</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>ÇIFT LAVAŞ BORU DÜRÜM</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    {/* <div className="item">
                        <div className="text_block">
                            <span>Chicken Burger</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span> Jumbo Burger </span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Special Burger</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Chicken Burger</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span> Jumbo Burger </span>
                            <span className="mark_star">*</span>
                        </div>
                    </div> */}
                </Slider>
            </div>
        </>
    )
}

export default Textlist